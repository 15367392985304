import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from '@/components/Login.vue'
import Serials from '@/components/Serials.vue'
import Books from '@/components/Books.vue'
import Book from '@/components/Book.vue'
import EditBook from '@/components/EditBook'
import EditSerial from '@/components/EditSerial'
import Sections from '@/components/Sections.vue'
import Section from '@/components/Section.vue'
import Shelfread from '@/components/Shelfread'
Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { auth: false }
    },
    { 
      path: '/book/:id',
      name: 'Book',
      component: Book
    },
    {
      path: '/subjects',
      name: 'Subjects',
      component: () => import('@/components/Subjects')
    },
    {
      path: '/fast/:fast_id',
      name: 'Subject',
      component: Books
    },
    {
      path: '/housekeeping',
      name: 'Housekeeping',
      component: () => import('@/components/housekeeping/Housekeeping'),
      meta: { auth: ['admin'] }, 
      children: [
        { 
          path: 'unclassified',
          name: 'Unclassified',
          component: () => import('@/components/housekeeping/Unclassified')
        },
        {
          path: '/dewey_codes',
          name: 'Deweys',
          component: () => import('@/components/housekeeping/Deweys')
        },
        { 
          path: 'print_list',
          name: 'PrintList',
          component: () => import('@/components/housekeeping/PrintList')
        },
        { 
          path: 'map',
          name: 'Map',
          component: () => import('@/components/housekeeping/Map')
        },
        {
          path: 'check_call_numbers',
          name: 'Checkcns',
          component: () => import('@/components/housekeeping/Checkcns')
        },
        {
          path: 'problem_authors',
          name: 'ProblemAuthors',
          component: () => import('@/components/housekeeping/ProblemAuthors')
        },
        {
          path: 'healthy_authors',
          name: 'HealthyAuthorCheck',
          component: () => import('@/components/housekeeping/HealthyAuthorCheck')
        },
        {
          path: 'old_order',
          name: 'OldOrder',
          component: () => import('@/components/housekeeping/OldOrder')
        }
      ]
    },
    { 
      path: '/sections',
      name: 'Sections',
      component: Sections
    },
    { 
      path: '/section/:section_id',
      name: 'Section',
      component: Books
    },
    { 
      path: '/collections',
      name: 'Collections',
      component: () => import('@/components/Collections')
    },
    // { 
    //   path: '/collection/:id',
    //   name: 'Collection',
    //   component:  () => import('@/components/Collection')
    // },
    { 
      path: '/books/by_classification/:id',
      name: 'ByClassification',
      component: () => import('@/components/ByClassification')
    },
    {
      path: '/collections/new',
      name: 'NewCollection',
      component: () => import('@/components/EditCollection'),
      meta: { auth: ['admin'] }
    },
    {
      path: '/collection/:id/edit',
      name: 'EditCollection',
      component: () => import('@/components/EditCollection'),
      meta: { auth: ['admin'] }
    },    
    {
      path: '/books/new',
      name: 'NewBook',
      component: EditBook,
      meta: { auth: ['admin'] }
    },
    {
      path: '/books/:id/edit',
      name: 'EditBook',
      component: EditBook,
      meta: { auth: ['admin'] }
    },
    {
      path: '/serials/new',
      name: 'NewSerial',
      component: EditSerial,
      meta: { auth: ['admin'] }
    },
    {
      path: '/serials/:id/edit',
      name: 'EditSerial',
      component: EditSerial,
      meta: { auth: ['admin'] }
    },
    {
      path: '/section/:id/shelfread',
      name: 'Shelfread',
      component: Shelfread
      // meta: { auth: ['admin'] }
    },
    { 
      path: '/serials',
      name: 'Serials',  
      component: Serials
    },
    {
      path: '/',
      name: 'Books',
      component: Books,
      props: true
      // beforeEnter: (to, from, next) => {
      //    if (to.name !== from.name) {
      //      console.log('fuck')
      //     window.location.reload()
      //   }
      //   return next()
      //  }
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    }
  ]
});
