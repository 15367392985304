<template>
  <div class="container">
    <div v-if="!fetching && serials">
       <h1>Serials</h1>
       <Pagination    
        :total-pages="totalPages"
        :per-page="50"
        :current-page="parseInt(currentPage)"
        @pagechanged="onPageChange"
     />
     <table class="table" v-bind="$props">
        <thead>
          <tr>
            <th>Title</th>
            <th>Issue</th>
            <th>When</th>
            <th>Publisher</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="serial in serials" :key ="serial.id">
            <td>{{ serial.attributes.title }}</td>
            <td>{{ serial.attributes.volume }} 
              <span v-if="serial.attributes.number">#</span>{{ serial.attributes.number }}
            </td>
            <td>{{ serial.attributes.month }} {{ serial.attributes.year }}</td>
            <td>{{ serial.attributes.publisher }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
       <Pagination v-if="totalPages > 0"
         :total-pages="totalPages"
         :per-page="50"
         :current-page="parseInt(currentPage)"
         @pagechanged="onPageChange"
        />
    </div>
    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue' 
import normalize from 'json-api-normalizer'

export default {
  name: 'Serials',
  components: { Pagination },
  data () {
    return {
      serials: [],
      totalPages: undefined,
      currentPage: 1,
      fetching:true
    }
  },
  methods: {
    onPageChange(page) {
      localStorage.setItem('serialPage', page)
      this.fetching = true
      this.currentPage = page
      this.loadData()
    },
    loadData() {
      let url = '/v1/serials?page=' + this.currentPage
     
      this.axios.get(url)
      .then((response) => {
        // this.$emit('interface', { books: response.data.data, paginationMeta: response.data.links, totalPages: response.data.meta.total })
        this.fetching = false
      })
    }
  },
  mounted () {
    this.axios.get('/v1/serials')
      .then((response) => {
        this.serials = response.data.data
        this.fetching = false
        this.totalPages = response.data.meta.total
      })
  }
}
</script>