<template>
  <div class="container"  v-if="!fetching && $auth.ready() && collections ">
    <router-link v-if="$auth.check('admin')" :to="{name: 'EditBook', params: {id: book.id }}">Edit book</router-link>
    <div class="columns">
      <div class="column">
        <h1 class="title">{{ book.attributes.title }}</h1>
        <h2 class="subtitle">{{book.attributes.author }}</h2>
        <h2 v-if="book.attributes.artist" class="subtitle">Artist: {{book.attributes.artist }}</h2>
      </div>
    </div>
    <div class="columns">
      <div class="column has-text-left is-4">
        <div v-if="book.attributes.image_url != '/book_missing.png'">
          <img :src="book.attributes.image_url" />
        </div>
      </div>
      <div class="column has-text-left">
         <p>
          <strong>Collection:</strong>
          <span v-if="book.attributes.collection_id">{{ collections[book.attributes.collection_id].attributes.name }}</span>
          <span v-else><i>Not part of a collection yet.</i></span>
         </p>
        <p v-if="book.attributes.section_id"><strong>Old section:</strong> {{ sections[book.attributes.section_id].attributes.name }}</p>
        <p v-if="book.attributes.section_id"><strong>Old sections catalogue #:</strong> {{ book.attributes.catno }} </p>
        <p><strong>Status:</strong> {{ statuses[book.attributes.status_id] }}</p>
        <p>&nbsp;</p>
        <p><strong>ISBN:</strong>
          <span v-if="book.attributes.isbn10">{{ book.attributes.isbn10 }}</span>
          <span v-if="book.attributes.isbn13">{{ book.attributes.isbn13 }}</span>
        </p>
        <p><strong>Publisher:</strong> {{ book.attributes.publisher }}</p>
        <p><strong>Year:</strong> {{ book.attributes.year_published }}</p>
        <p><strong>Subtitle:</strong> {{ book.attributes.subtitle }}</p>
        <p><strong>Summary:</strong> {{ book.attributes.summary }}</p>
        <p><strong>Pages:</strong> {{ book.attributes.pages }}</p>
        <p><strong>Provenance:</strong> {{ book.attributes.provenance }}</p>
        <p>&nbsp;</p>
        <p><strong>DDC:</strong> {{ book.attributes.ddc }}</p>
        <p><strong>LCC:</strong> {{ book.attributes.lcc }}</p>
        <p><strong>OCLC:</strong>
          <a v-if="book.attributes.oclc && !book.attributes.not_found" :href="'https://worldcat.org/oclc/' + book.attributes.oclc" target="_blank">
            {{ book.attributes.oclc }}
          </a>
          <span v-else class="tag is-danger">not found</span>
        </p>
        <p>&nbsp;</p>
        <p class="is-size-4"><strong>Call number:</strong> {{ book.attributes.call_number }}</p>
        <p><strong>Number of copies:</strong> {{ book.attributes.copies }}</p>
        <div v-if="book.attributes.fast_headings.length > 0">
          <p><strong>FAST subject headings:</strong>
            <ul>
              <li v-bind:key="fast.id" v-for="fast in book.attributes.fast_headings">
                <router-link :to="{name: 'Subject', params: {fast_id: fast.id}}">{{ fast.name }}</router-link>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <img src="@/assets/ajax-loader.gif"/>
  </div>
</template>
<script>
import normalize from 'json-api-normalizer'
export default {
  data () {
    return {
      fetching: true,
      book: {},
      collections: {},
      sections: {},
      statuses: {
        1: 'On shelf',
        2: 'Borrowed',
        3: 'Missing'
      }
    }
  },
  mounted () {
    this.axios.get('/v1/collections')
    .then((response) => {
      this.collections = normalize(response.data).collection || {}

    })
    this.axios.get('/v1/sections')
    .then((response) => {
      this.sections = normalize(response.data).section
    })
    .then(() => {
      this.axios.get('/v1/books/' + this.$route.params.id)
      .then((response) => {
        this.book = response.data.data
        this.fetching = false
      })
    })
  }
}
</script>