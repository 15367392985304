<template>
  <nav class="pagination is-centered" role="navigation" aria-label="pagination">
    <a href='#'
      class="pagination-previous"
      @click="onClickFirstPage"
      :disabled="isInFirstPage"
      aria-label="Go to first page"
    >
      First
    </a>
    <a href="#" class="pagination-previous"
      @click="onClickPreviousPage"
      :disabled="isInFirstPage"
      aria-label="Go to previous page"
    >
      Previous
    </a>
    <ul class="pagination-list">
    <li
      v-for="page in pages" :key="page.name">
      <a class="pagination-link" 
        :key="page.name"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
        :aria-label="`Go to page number ${page.name}`"
      >
      {{ page.name }}
      </a>
    </li>
  </ul>
  <a href="#" class="pagination-next"
    @click="onClickNextPage"
    :disabled="isInLastPage"
    aria-label="Go to next page"
    >
    Next
  </a>
  <a href="#" class="pagination-next"
        @click="onClickLastPage"
        :disabled="isInLastPage"
        aria-label="Go to last page"
  >
    Last
  </a>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 7
    },
    totalPages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }
      // When on the last page
      if (this.currentPage === this.totalPages) {
        console.log('this should')
        let c =  this.totalPages - this.maxVisibleButtons
        if (c < 1) {
          return 1
        } else { return c }
      }
      // When in between
      if (this.currentPage > 1 ) {
        return this.currentPage - 1;
      } else {
        return 1;
      }
    },
    endPage() {
      
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
      
    },
    pages() {
      const range = [];

      for (let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i+= 1 ) {
        // if (i < 1) {}
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', parseInt(this.currentPage) - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', parseInt(page));
    },
    onClickNextPage() {
      this.$emit('pagechanged', parseInt(this.currentPage) + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', parseInt(this.totalPages));    
    },
    isPageActive(page) {
      return this.currentPage == page;
    },
  }

};
</script>
