<template>
  <section v-if="showmenu">
    <div v-if="!fetching && $auth.ready()">
      <div class="columns">
        <h1 class="title">Sections</h1>
      </div>
      <div :class="{'has-background-white-ter': index % 2 === 0}" class="columns" v-for="(section, index) in sections" :key="section.id">
          <div class="level has-text-left">
            <h2 class="subtitle">
              <router-link :to="'/section/' + section.id " :style="'color: ' + section.attributes.colour">
                {{section.attributes.name }}
              </router-link>
            </h2>

          <p class="section_count"> {{ section.attributes.book_count }} books</p>

          <router-link class="button is-small is-outlined is-secondary is-size-7" v-if="$auth.check('admin')" :to="{name: 'Shelfread', params: {id: section.id }}">shelf read</router-link>
        </div>

      </div>
    </div>

    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </section>
  <section v-else>
      <Books v-if="done" :restrictSection="this.$route.params.id"  :books="books" :totalPages="totalPages" :fetching="fetching" :searchTerm="searchTerm" :paginationMeta="paginationMeta" @fetching="processFetch" @interface="handleStuff" />
  </section>
</template>
<script>
import Books from '@/components/Books.vue'
export default {
  data () {
    return {
      fetching: true,
      showmenu: true,
      sections: []
    }
  },
  components: {
    Books
  },
  mounted () {
    this.$attrs.searchTerm = null
    this.axios.get('/v1/sections')
    .then((response) => {
      this.sections = response.data.data
      this.fetching = false
    })
  }
}
</script>