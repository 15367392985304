<template>
  <div class="container">
    <div class="columns is-centered">
      <h3 class="title is-3">
        <span v-if="editing">Edit book</span>
        <span v-else>Add new book</span>
        <button class="button is-danger is-small" @click="destroyBook">Delete book</button>
      </h3>
    </div>
    <div v-if="!editing">
      <form v-on:submit.prevent="searchOCLC">
        <label class="label">Try to get stuff automatically from OCLC:</label>
        <input class="input" type="text" placeholder="Scan a barcode/ISBN or search for title/author" v-model="oclcsearch" />
        <button type="submit">Search</button>
      </form>
      <div v-show="searchingOCLC">
        <img src="@/assets/ajax-loader.gif" />
      </div>
    </div>
    <form v-if="book" v-on:submit.prevent="submitBook">
      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Title</label>
            <input class="input" type="text" placeholder="Enter the title of this book" v-model="book.attributes.title" />
          </div>
          <div class="field">
            <label class="label">Author</label>
            <input class="input" type="text" placeholder="Enter the author(s) of this book" v-model="book.attributes.author" />
          </div>
          <div class="field">
            <label class="label">Artist</label>
            <p class="is-size-7">If this book is about an artist and should be alphabetised under the artist's name, not the author or editor, enter the artist name here and the generated call number will use this field instead of the author for the alphabetisation Cutter.</p>
            <input class="input" type="text" placeholder="Enter the artist the book is about (optional)" v-model="book.attributes.artist" />
          </div>
          <div class="field">
            <input type="checkbox" placeholder="Enter the author(s) of this book" v-model="book.attributes.author_is_editor" /><label class="label">Author is editor?</label> <br />
            <input type="checkbox" placeholder="Enter the author(s) of this book" v-model="book.attributes.author_is_institution" /><label class="label">Author is institution?</label>
          </div>
          <div class="field">
            <label class="label">Contributors</label>
            <input class="input" type="text" placeholder="Enter the contributors to this book" v-model="book.attributes.contributors" />
          </div>
          <div class="field">
            <label class="label">Call # (blank on new book will generate one automatically)</label>
            <input class="input" type="text" placeholder="Enter the call number of to this book" v-model="book.attributes.call_number" />
            <label class="label">
              <input v-if="book.id" type="checkbox" value="reset_callno" /> Regenerate call number
            </label>
          </div>
          <div class="field">
            <label class="label">Publisher</label>
            <input class="input" type="text" placeholder="Enter the publisher of this book" v-model="book.attributes.publisher" />
          </div>
          <div class="field">
            <label class="label">Year published</label>
            <input class="input" type="text" placeholder="Enter the year when this book was published" v-model="book.attributes.year_published" />
          </div>
          <div class="field">
            <label class="label">Dewey decimal class</label>
            <input class="input" type="text" placeholder="Enter the Dewey # for this book"   v-model="book.attributes.ddc" />
          </div>
          <div class="field">
            <label class="label">OCLC id:</label>
            <input class="input" type="text" placeholder="Enter the OCLC # for this book"   v-model="book.attributes.oclc" />
          </div>
          <label class="label">Collection</label>
          <div class="select">
            
            <select v-model.trim="book.attributes.collection_id" mame="collection_id" id="collection_id">
              <option value=""><em>Uncollected</em></option>
              <option v-for="collection in collections" :key="collection.id" :value="collection.id">{{collection.attributes.name }}</option>
            </select>
          </div>

          <div class="field">
            <label class="label">Catalogue #</label>
            <input class="input" type="text" placeholder="Enter the catalogue # for this book"   v-model="book.attributes.catno" />
          </div>
          <div class="select">
            <label class="label">Status</label>
            <select v-model.trim="book.attributes.status_id" mame="status_id" id="status_id">
              <option value="1">On shelf</option>
              <option value="2">Borrowed</option>
              <option value="3">Missing</option>
            </select>
          </div>

        </div>
        <div class="column is-half">
          <div class="field">
            <label class="label">Number of copies:</label>
            <input class="input" type="text" placeholder=""   v-model="book.attributes.copies" />
          </div>
          <div class="field">
            <label class="label">ISBN10 #</label>
            <input class="input" type="text" placeholder="Enter the 10-digit ISBN# for this book"   v-model="book.attributes.isbn10" />
          </div>
                    <div class="field">
            <label class="label">Number of copies:</label>
            <input class="input" type="text" placeholder=""   v-model="book.attributes.copies" />
          </div>
          <div class="field">
            <label class="label">Language:</label>
            <input class="input" type="text" placeholder=""   v-model="book.attributes.language" />
          </div>          
          <div class="field">
            <label class="label">ISBN13 #</label>
            <input class="input" type="text" placeholder="Enter the 13-digit ISBN# for this book"   v-model="book.attributes.isbn13" />
          </div>
          <div class="field">
            <label class="label">Subtitle</label>
            <input class="input" type="text" placeholder="Enter the subtitle for this book"   v-model="book.attributes.subtitle" />
          </div>
          <div class="field">
            <label class="label">Provenance</label>
            <input class="input" type="text" placeholder="Enter the provenance of this book"   v-model="book.attributes.provenance" />
          </div>
          <div class="field">
            <label class="label">Summary</label>
            <input class="input" type="text" placeholder="Enter a summary about this book"   v-model="book.attributes.summary" />
          </div>
          <div class="field">
            <label class="label">Pages</label>
            <input class="input" type="text" placeholder="How many pages are in this book?"   v-model="book.attributes.pages" />
          </div>
          <div class="field">
            <label class="label">Comment</label>
            <textarea class="textarea" placeholder="Enter a staff-only comment about this book"   v-model="book.attributes.comment" />
          </div>        
          <div class="field">
            <img :src="imgPreview" />
            <div v-if="book.attributes.image_url">
              <img :src="book.attributes.image_url" />
            </div>
            <label class="label">Image</label>
              <input class="file-input" type="file" ref="imageFile" @change="previewFiles($event)">
          </div> 
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <button type="submit" class="button is-primary">Save</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'EditBook',
  data () {
    return {
      book: {
        attributes: {
          title: null,
          author: null,
          publisher: null,
          ddc: null,
          isbn10: null,
          isbn13: null,
          collection_id: null,
          reset_callno: false,
          pages: null,  
          artist: null,
          year_published: null,
          subtitle: null, 
          catno: null,
          section_id: null,
          language: null,
          oclc: null,
          status_id: null,
          summary: null,
          image: null,
          comment: null,
          provenance: null,
          author_is_editor: null,
          call_number: null,
          copies: 1,
          author_is_institution: null,
          contributors: null
        }
      },
      searchingOCLC: false,
      oclcsearch: null,
      uploadedImage: null,
      imgPreview: '',
      formData: new FormData(),
      collections: [],
      editing: false
    }
  },
  methods: {
    searchOCLC() {
      this.searchingOCLC = true
      this.axios.get('/v1/books/search_oclc?search=' +  this.oclcsearch)
        .then((resp) => {
          if (resp.status === 200) {
            // console.log(resp.data)
            this.$toasted.success("Success, we found this book and were able to populate these fields automatically in the OCLC database!", {
              position: 'top-right',
              fullWidth: false,
              theme: 'bubble',
              closeOnSwipe: true,
              duration: 6000
            })
            this.book = resp.data.data
            this.searchingOCLC = false
          }
          else if (resp.status === 404 || resp.status === 204) {
            this.$toasted.error("Nah, couldn't find it, sorry, you gotta enter it by hand.", {
              position: 'top-right',
              fullWidth: false,
              theme: 'bubble',
              closeOnSwipe: true,
              duration: 6000
            })
            this.searchingOCLC = false
          }
        })
        
    },
    previewFiles (file) {

      let files = this.$refs.imageFile.files
      this.uploadedImage = this.$refs.imageFile
      let reader = new FileReader()
      reader.onload = (event) => {
        this.imgPreview = event.target.result
      }
      reader.readAsDataURL(files[0])
      this.formData.append('book[image]', files[0])
    },
    submitBook () {
      let BOOK_URL = '/v1/books'
      let method = 'post'
      let newbook =  this.book.attributes
      this.formData.append('book[isbn10]', this.book.attributes.isbn10 == null ? '': this.book.attributes.isbn10)
      this.formData.append('book[isbn13]', this.book.attributes.isbn13 == null ? '': this.book.attributes.isbn13)
      this.formData.append('book[title]', this.book.attributes.title)
      this.formData.append('book[language]', this.book.attributes.language == null ? '': this.book.attributes.language)
      this.formData.append('book[publisher]', this.book.attributes.publisher == null ? '': this.book.attributes.publisher)
      this.formData.append('book[pages]', this.book.attributes.pages == null ? '': this.book.attributes.pages)
      this.formData.append('book[collection_id]', this.book.attributes.collection_id)
      this.formData.append('book[ddc]', this.book.attributes.ddc)
      this.formData.append('book[oclc]', this.book.attributes.oclc)
      this.formData.append('book[reset_callno]', this.book.attributes.reset_callno)
      this.formData.append('book[contributors]', this.book.attributes.contributors == null ? '': this.book.attributes.contributors)
      if (this.book.attributes.call_number || this.$route.params.id) {
        this.formData.append('book[call_number]',this.book.attributes.call_number)
      }
      this.formData.append('book[artist]', this.book.attributes.artist == null ? '': this.book.attributes.artist)
      this.formData.append('book[author_is_editor]', this.book.attributes.author_is_editor)
      this.formData.append('book[author_is_institution]', this.book.attributes.author_is_institution)
      this.formData.append('book[year_published]', this.book.attributes.year_published == null ? '': this.book.attributes.year_published)
      this.formData.append('book[author]', this.book.attributes.author == null ? '': this.book.attributes.author)
      this.formData.append('book[subtitle]', this.book.attributes.subtitle == null ? '': this.book.attributes.subtitle)
      this.formData.append('book[comment]', this.book.attributes.comment == null ? '': this.book.attributes.comment)
      this.formData.append('book[copies]', this.book.attributes.copies == null ? '': this.book.attributes.copies)
      this.formData.append('book[catno]', this.book.attributes.catno == null ? '': this.book.attributes.catno)
      this.formData.append('book[provenance]', this.book.attributes.provenance == null ? '': this.book.attributes.provenance)
      this.formData.append('book[summary]', this.book.attributes.summary == null ? '': this.book.attributes.summary)

      this.formData.append('book[section_id]', this.book.attributes.section_id)
      this.formData.append('book[status_id]', this.book.attributes.status_id)
 
      if (this.editing) {
        BOOK_URL = `/v1/books/${this.book.id}`
        method = 'put'
      }
      this.axios({method: method, url: BOOK_URL, data: this.formData,
        header: {
          'Content-Type' : 'multipart/form-data'
        }
      })
        .then((response) => {
          this.$router.push({ name: 'Book', params: { id: response.data.data.id } })
        })
        .catch(error => {
          // console.log(error)
        })
    },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    },
    destroyBook() {
      if (confirm('ARE YOU SURE YOU WANT TO DESTROY THIS BOOK IN THE DATABASE????'))  {
        this.axios.delete('/v1/books/' + this.$route.params.id)
        .then((resp) => {
          if (resp.status === 204) {
            this.$toasted.success('The book has been deleted!', {
              position: 'bottom-center',
              fullWidth: true,
              fitToScreen: true,
              closeOnSwipe: true,
              duration: 4000
            })
            this.$router.push('/housekeeping/check_call_numbers')
          }
        }) 
      }
    }
  },
  mounted () {
    this.axios.get('/v1/collections')
    .then((response) => {
      this.collections = response.data.data
    })
    if (this.$route.params.id) {
      this.editing = true
      let DATA_URL = `/v1/books/${this.$route.params.id}`
      this.axios.get(DATA_URL)
      .then((response) => {
        this.book = response.data.data
      })
      .catch(error => {
        // console.log(error)
      })
    }
  }
}
</script>