<template>
  <div id="app" class="container" v-if="$auth.ready() && loaded">
    <div id="nav">

      <span v-show="$auth.check('admin')">
        <router-link to="/books/new">New book</router-link> |
        <router-link to="/serials/new">New serial</router-link> |
        <router-link :to="{name: 'Housekeeping'}">Housekeeping</router-link> |
      </span>
      <a href='#' @click="loadAllBooks">All books</a> |
      <router-link :to="{name: 'Collections'}">Collections</router-link> |
      <router-link :to="{name: 'Sections'}">Old sections</router-link> |
      <router-link :to="{name: 'Subjects'}">FAST headings</router-link> 
      <span v-show="$auth.check()">
        <!-- <router-link :to="{name: 'account'}">account</router-link> &bull; -->
        | <a v-on:click="logout()" href="javascript:void(0);">Log out</a>
      </span>
      <span v-show="$auth.impersonating()">
         &bull;
        <a v-on:click="unimpersonate()" href="javascript:void(0);">(logout {{ $auth.user().username }})</a>
      </span>
      <form v-on:submit.prevent="sendSearch">
        <input id="searchbar" placeholder="Search..." v-model="presearch" />
      </form>
    </div>
    <router-view :key="$route.fullPath" :books="books" :fastSection="fastSection" :restrictSection="restrictSection" :restrictCollection="restrictCollection"  :totalPages="totalPages" :fetching="fetching"  :searchTerm="searchTerm" :paginationMeta="paginationMeta" @fetching="processFetch" @interface="handleStuff" @setSection="setSection" @setCollection="setCollection" />

  </div>
</template>
<script>
import Vue from 'vue'
import Books from '@/components/Books'
import _ from 'lodash'
import router from './router'
export default {

  data() {
    return {
      context: 'app context',
      searchTerm: null,
      presearch: null,
      loaded: false,
      totalPages: null,
      paginationMeta: null,
      books: null,
      restrictCollection: "0",
      restrictSection: "0",
      fastSection: "0",
      token: '',
      fetching: true
    };
  },
  // components: {
  //   Books
  // },
  beforeMount() {
    if (!this.books && this.restrictSection.toString() === "0") {
      console.log('no books so default')
      window.localStorage.setItem('bookPage', 1)
      // this.setSection(this.restrictSection)
      // this.setFast(this.fastSection)
      this.setCollection(this.restrictCollection)
    }
  },
  mounted() {
    const _this = this;
    // Set up $auth.ready with other arbitrary loaders (ex: language file).
    setTimeout(() => {
      _this.loaded = true;
    }, 500);
  },
  created() {
    const _this = this;
    this.$auth.ready(function () {
      console.log(`ready ${this.context}`);
    });
    this.axios.interceptors.response.use((response) => {
      return response
    }, function (error) {
      let originalRequest = error.config
      if (error.response) {
        if (error.response.status === 401 && !originalRequest._retry) {
          console.log(originalRequest)
          router.push({ name: 'Login' })
        } else if (error.response.status === 403) {
          console.log('403 error')
          // console.log('storing new access token : ' + originalRequest.headers['access-token'])
          // localStorage.setItem('access-token', originalRequest.headers['access-token'])
          router.push({ name: 'Profile' })
        } else if (error.response.status === 422) {
          if (error.response.data.errors.full_messages) {
            error.message = error.response.data.errors.full_messages.join('; ')
          } else {
            console.log(error.response.data.errors)
            const errorMessages = _.map(error.response.data.errors, function (e) {
              if (e.source) {
                return e.source.parameter + ' ' + e.detail
              } else if (e.title) {
                return e.title
              }
            })
            error.message = errorMessages.join('; ')
          }
        } else {
          console.log('unknown error')
        }
      } else {
        console.log('error response: ')
        // console.log(response)
        console.log('it is: ' + originalRequest)
      }
      let duration = 5000
      if (error.message === 'Network Error') {
        error.message = 'Network error - perhaps the API is not reachable?'
        duration = null
      }
      if (!/auth\/validate_token$/.test(error.config.url)) {
        Vue.toasted.error('Oops! Something went wrong: ' + error.message, {
          position: 'bottom-center',
          fullWidth: true,
          fitToScreen: true,
          closeOnSwipe: true,
          duration: duration
        })
      }
      return Promise.reject(error)
    })
  },
  methods: {
    handleStuff (event) {
      this.books = event.books
      this.paginationMeta = event.paginationMeta
      this.totalPages = event.totalPages
    },
    processFetch(event) {
      this.fetching = event
    },
    loadAllBooks () {
      this.$router.push("/")
      this.restrictSection = "0"
      this.fastSection = "0"
      this.searchTerm = ''
      this.presearch = ''
      this.books = []
      this.fetching = true
      this.axios.get('/v1/books')
        .then((response) => {
          this.books = response.data.data
          this.paginationMeta = response.data.links
          this.totalPages = response.data.meta.total
          this.fetching =  false
        })
    },
    setToken(name) {
      this.token = this.$auth.token(name);
    },
    clearSearch() {
      this.presearch = ''
    },
    setCollection(event) {
      console.log('filtering by collection')
      
      this.searchTerm = ''
       this.presearch = ''
      this.fetching = true
      this.restrictCollection = event  
      let url = '/v1/books'
      if (parseInt(this.restrictCollection) > 0) {
        url = '/v1/collections/' + this.restrictCollection + '/books'
      } else {
        console.log(parseInt(this.restrictCollection))
      }
      console.log(url)
      this.axios.get(url)
        .then((response) => {
          console.log('loaded from App.vue: ' + this.restrictCollection)
          this.books = response.data.data
          this.paginationMeta = response.data.links
          this.totalPages = response.data.meta.total
          this.fetching =  false
        })
    },
    setFast(event) {
      this.fetching = true
      this.fastSection = event  
       this.presearch = ''
      let url = '/v1/books'
      if (parseInt(this.fastSection) > 0) {
        url = '/v1/fasts/' + this.fastSection + '/books'
      }
      // this.axios.get(url)
      //   .then((response) => {
      //     console.log('loaded from App.vue: ' + url)
      //     this.books = response.data.data
      //     this.paginationMeta = response.data.links
      //     this.totalPages = response.data.meta.total
      //     this.fetching =  false
      //   })
    },
    setSection(event) {
      console.log('setting')
      console.log(event)
      this.searchTerm = ''
       this.presearch = ''
      this.fetching = true
      this.restrictSection = event  
      let url = '/v1/books'
      if (parseInt(this.restrictSection) > 0) {
        url = '/v1/sections/' + this.restrictSection + '/books'
      }
      this.axios.get(url)
        .then((response) => {
          console.log('loaded from setSection App.vue: ' + this.restrictSection)
          this.books = response.data.data
          this.paginationMeta = response.data.links
          this.totalPages = response.data.meta.total
          this.fetching =  false
        })
    },
    sendSearch () {
      this.searchTerm = this.presearch
      // this.$router.push({name: 'Books', props: {searchTerm: this.searchTerm }})
    },
    logout() {

      this.$auth.logout({
        makeRequest: true,
        success() {
          console.log(`success ${this.context}`);
        },
        error(e) {
          console.log(e)
          console.log(`error ${this.context}`);
        },
      });
    },
    unimpersonate() {
      this.$auth.unimpersonate({
        success() {
          console.log(`success ${this.context}`);
        },
        error() {
          console.log(`error ${this.context}`);
        },
      });
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: #4AAE9B;
  color: #ffffff;
}
img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  border-style: none;
}
</style>
<style lang="scss">
@import 'assets/css/nida.scss';
</style>