<template>
  <div class="container">
    <div class="columns is-centered">
      <h3 class="title is-3">
        <span v-if="editing">Edit serial</span>
        <span v-else>Add new serial</span>
        <button class="button is-danger is-small" @click="destroySerial">Delete serial</button>
      </h3>
    </div>
    <form v-if="serial" v-on:submit.prevent="submitSerial">
      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Title</label>
            <input class="input" type="text" placeholder="Enter the title of this serial" v-model="serial.attributes.title" />
          </div>
          <div class="field">
            <label class="label">Subtitle</label>
            <input class="input" type="text" placeholder="Enter the subtitle of this serial if there is one" v-model="serial.attributes.subtitle" />
          </div>
          <div class="field">
            <label class="label">Volume</label>
            <input class="input" type="text" placeholder="Enter the volume #" v-model="serial.attributes.volume" />
          </div>
          <div class="field">
            <label class="label">Number</label>
            <input class="input" type="text" placeholder="Enter the issue number of this serial" v-model="serial.attributes.number" />
          </div>
          <div class="field">
            <label class="label">Year</label>
            <input class="input" type="text" placeholder="Enter the year of this serial" v-model="serial.attributes.year" />
          </div>
          <div class="field">
            <label class="label">Month</label>
            <input class="input" type="text" placeholder="Enter the month or season (spring, summer) of this serial" v-model="serial.attributes.month" />          
          </div>
          <div class="field">
            <label class="label">Publisher</label>
            <input class="input" type="text" placeholder="Enter the publisher of this serial" v-model="serial.attributes.publisher" />
          </div>

          <div class="select">
            <label class="label">Status</label>
            <select v-model.trim="serial.attributes.status_id" mame="status_id" id="status_id">
              <option value="1">On shelf</option>
              <option value="2">Borrowed</option>
              <option value="3">Missing</option>
            </select>
          </div>

        </div>
        <div class="column is-half">
          <div class="field">
            <label class="label">Number of copies:</label>
            <input class="input" type="text" placeholder=""   v-model="serial.attributes.copies" />
          </div>
          <div class="field">
            <label class="label">ISSN #</label>
            <input class="input" type="text" placeholder="Enter the ISSN# for this serial"   v-model="serial.attributes.issn" />
          </div>
           
          <div class="field">
            <label class="label">Language:</label>
            <p class="is-size-7">Enter a two digit code, en = English, lt = Lithuanian, de = German, etc. For mutiple languages separate codes by /s</p>
            <input class="input" type="text" placeholder="" v-model="serial.attributes.language" />
          </div>          
          <div class="field">
            <label class="label">Provenance</label>
            <input class="input" type="text" placeholder="Enter the provenance of this serial"   v-model="serial.attributes.provenance" />
          </div>
         
          <div class="field">
            <label class="label">Comment</label>
            <textarea class="textarea" placeholder="Enter a staff-only comment about this serial"   v-model="serial.attributes.comment" />
          </div>        
          <div class="field">
            <img :src="imgPreview" />
            <div v-if="serial.attributes.image_url">
              <img :src="serial.attributes.image_url" />
            </div>
            <label class="label">Image</label>
              <input class="file-input" type="file" ref="imageFile" @change="previewFiles($event)">
          </div> 
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <button type="submit" class="button is-primary">Save</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'EditSerial',
  data () {
    return {
      serial: {
        attributes: {
          title: null,
          subtitle: null,
          publisher: null,
          volume: null,
          number: null,
          month: null,
          issn: null,
          year: null,
          language: null,
          status_id: null,
          image: null,
          comment: null,
          provenance: null,
          copies: 1,

        }
      },
    
      uploadedImage: null,
      imgPreview: '',
      formData: new FormData(),
    
      editing: false
    }
  },
  methods: {
    previewFiles (file) {

      let files = this.$refs.imageFile.files
      this.uploadedImage = this.$refs.imageFile
      let reader = new FileReader()
      reader.onload = (event) => {
        this.imgPreview = event.target.result
      }
      reader.readAsDataURL(files[0])
      this.formData.append('serial[image]', files[0])
    },
    submitSerial () {
      let BOOK_URL = '/v1/serials'
      let method = 'post'
      let newserial =  this.serial.attributes
      this.formData.append('serial[issn]', this.serial.attributes.issn == null ? '': this.serial.attributes.issn)
      this.formData.append('serial[title]', this.serial.attributes.title)
      this.formData.append('serial[language]', this.serial.attributes.language == null ? '': this.serial.attributes.language)
      this.formData.append('serial[publisher]', this.serial.attributes.publisher == null ? '': this.serial.attributes.publisher)
      this.formData.append('serial[subtitle]', this.serial.attributes.subtitle == null ? '': this.serial.attributes.subtitle)
      this.formData.append('serial[year]', this.serial.attributes.year == null ? '': this.serial.attributes.year)


      this.formData.append('serial[comment]', this.serial.attributes.comment == null ? '': this.serial.attributes.comment)
      this.formData.append('serial[copies]', this.serial.attributes.copies == null ? '': this.serial.attributes.copies)

      this.formData.append('serial[provenance]', this.serial.attributes.provenance == null ? '': this.serial.attributes.provenance)
      this.formData.append('serial[volume]', this.serial.attributes.volume == null ? '': this.serial.attributes.volume)
      this.formData.append('serial[number]', this.serial.attributes.number == null ? '': this.serial.attributes.number)
      this.formData.append('serial[month]', this.serial.attributes.month == null ? '': this.serial.attributes.month)

      this.formData.append('serial[status_id]', this.serial.attributes.status_id)
 
      if (this.editing) {
        BOOK_URL = `/v1/serials/${this.serial.id}`
        method = 'put'
      }
      this.axios({method: method, url: BOOK_URL, data: this.formData,
        header: {
          'Content-Type' : 'multipart/form-data'
        }
      })
        .then((response) => {
          this.$router.push({ name: 'Serials' })
        })
        .catch(error => {
          // console.log(error)
        })
    },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    },
    destroySerial() {
      if (confirm('ARE YOU SURE YOU WANT TO DESTROY THIS SERIAL IN THE DATABASE????'))  {
        this.axios.delete('/v1/serials/' + this.$route.params.id)
        .then((resp) => {
          if (resp.status === 204) {
            this.$toasted.success('The serial has been deleted!', {
              position: 'bottom-center',
              fullWidth: true,
              fitToScreen: true,
              closeOnSwipe: true,
              duration: 4000
            })
            this.$router.push('/housekeeping/check_call_numbers')
          }
        }) 
      }
    }
  },
  mounted () {
    this.axios.get('/v1/collections')
    .then((response) => {
      this.collections = response.data.data
    })
    if (this.$route.params.id) {
      this.editing = true
      let DATA_URL = `/v1/serials/${this.$route.params.id}`
      this.axios.get(DATA_URL)
      .then((response) => {
        this.serial = response.data.data
      })
      .catch(error => {
        // console.log(error)
      })
    }
  }
}
</script>