<template>
  <div class="container">
    <div v-if="!fetching && books && collections && sections">
      <h1>Books
        <span v-if="parseInt(restrictSection) !== 0"> in old section <em>{{ sections[restrictSection].attributes.name }} </em>
          <a href="/" class="is-size-7 button is-link is-outline" @click="clearSection">clear</a>
        </span>
        <span v-if="parseInt(restrictCollection) !== 0"> in collection:<br /> <em>{{ collections[restrictCollection].attributes.name }} </em>
          <a href="/" class="is-size-7 button is-link is-outline" @click="clearSection">clear filter</a>
          <br /><br /><br />
        </span>
        <span v-if="searchTerm"> matching search term <em> {{ searchTerm }} </em></span>     
      </h1>

      <h2 v-if="fast && !searchTerm && parseInt(fastSection) !== 0" class="subtitle">
        FAST heading: {{ fast.attributes.name }}
         <a href="#" class="is-size-7 button is-link is-outline" @click="clearSection">clear</a>
      </h2>
      <Pagination    
        :total-pages="totalPages"
        :per-page="50"
        :current-page="parseInt(currentPage)"
        @pagechanged="onPageChange"
     />
      <table class="table" v-bind="$props">
        <thead>
          <tr>
            <th>Cat#</th>
            <th>&nbsp;</th>
            <th>Title</th>
            <th>Author</th>
            <th>Details</th>
            <th>Publisher</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="book in books" v-bind:key="restrictSection + fastSection + book.id" :class="statuses[book.attributes.status_id].replace(/\s/g, '').toLowerCase()">
            <td class="category"> 
              <div v-if="book.attributes.collection_id">
                <b>Collection: </b>
                <a href='#' @click="filterCollection(book.attributes.collection_id)">{{ collections[book.attributes.collection_id].attributes.name }}</a>
                </div>
              <div  v-if="book.attributes.section">Old section: <a href='#' @click="filterSection(book.attributes.section_id)">{{ book.attributes.section }}</a></div>

              <!-- <span v-if="book.attributes.section_id">{{ book.attributes.catno }}</span> -->
              <span v-if="book.attributes.ddc">
                <br />
                {{ book.attributes.call_number }}

              </span>

            </td>
            <td>
              <router-link :to="{name: 'Book', params: { id: book.id }}"><img v-if="book.attributes.image_url != '/book_missing.png'" :src="book.attributes.image_url" />
              </router-link>
            </td>
            <td class="book_title">{{ book.attributes.title }}</td>
            <td class="book_author is-size-6">
              {{ book.attributes.author }}
              <div v-if="book.attributes.artist">
                Artist: {{ book.attributes.artist }}
              </div>
            </td>
            <td class="is-size-7">{{ book.attributes.isbn13 }}<br />
                {{ book.attributes.language }}<br />
                {{ book.attributes.isbn10 }}
                {{ book.attributes.year_published }}<br />
                {{ book.attributes.pages }}
            </td>
            <td class="is-size-7">{{ book.attributes.publisher }}</td>
            <td>
              {{ statuses[book.attributes.status_id] }}
              <router-link :to="{name: 'Book', params: { id: book.id }}">
                <button class="button" >Details</button>
              </router-link>
              <router-link v-if="$auth.check()" :to="{name: 'EditBook', params: {id: book.id}}">
                <button class="button is-link">Edit book</button>
              </router-link>

            </td>
          </tr>
        </tbody>
      </table>
       <Pagination v-if="totalPages > 0"
         :total-pages="totalPages"
         :per-page="50"
         :current-page="parseInt(currentPage)"
         @pagechanged="onPageChange"
        />
    </div>
    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue' 
import normalize from 'json-api-normalizer'

export default {
  name: 'Books',
  props: {
    books: {
      type: Array,
      required: false,
    },
    searchTerm: {
      type: String,
      required: false,
      default: null
    },
    totalPages: {
      type: Number,
      required: false, 
      default: 0
    },
    paginationMeta: {
      type: Object,
      required: false
    },
    fastSection: {
      type: String,
      required: false,
      default: "0"
    },
    restrictSection: {
      type: String,
      required: false,
      default: "0"
    },
    restrictCollection: {
      type: String,
      required: false,
      default: "0"
    },
    fetching: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  components: {
    Pagination
  },
  watch: {
    searchTerm: function(newVal,oldVal) {
      this.searchLibrary()
    }
  },
  data () {
    return {
      currentPage: undefined,
      updating: false,
      fast: undefined,
      collections: undefined,
      sections: undefined,
      statuses: {
        1: 'On shelf',
        2: 'Borrowed',
        3: 'Missing'
      }
    }
  },
  // beforeMount () {
  //   if (this.searchResults) {
  //     this.books = this.searchResults
  //     console.log('loaded')
  //   } else {
  //     console.log('no search results')
  //   }
  // },
  created () {
    let bookPage = window.localStorage.getItem('bookPage')
    if (bookPage) {
      this.currentPage = bookPage
    } else {
      this.currentPage = 1
    }
    if (this.$route.params.section_id) {
      this.$emit('setSection', this.$route.params.section_id)
    }
    if (this.$route.params.collection_id) {
      this.$emit('setCollection', this.$route.params.section_id)
    }
    if (this.$route.params.fast_id) {
      this.axios.get('/v1/fasts/' + this.$route.params.fast_id)
        .then((resp) => {
          this.fast = resp.data.data
        })
      this.$emit('clearSearch')
      this.$emit('setFast', this.$route.params.fast_id)
    }
  },
  beforeMount () {
    if (!this.books && this.fetching && (this.restrictCollection || this.restrictSection )) {
      // this.loadData(this.currentPage, this.restrictSection)
      this.$emit('fetching', false)
    } else {
      this.$emit('fetching', false)
    }

    if (!this.collections) {
      this.axios.get('/v1/collections')
      .then((response) => {
        this.collections = normalize(response.data).collection
        if (!this.collections) {
          this.collections = {}
        }
      })
    }
    if (!this.sections) {
      this.axios.get('/v1/sections')
      .then((response) => {
        this.sections = normalize(response.data).section
      })
    }
  }, 
  methods: {
    filterSection(event) {
      window.localStorage.setItem('bookPage', 1)
      this.$emit('setSection', event.toString())
    },
    filterCollection(event) {
      window.localStorage.setItem('bookPage', 1)
      this.$emit('setCollection', event.toString())
    },
    clearSection () {
      window.localStorage.setItem('bookPage', 1)
      this.$emit('setSection', "0")
      this.$emit('setFast', "0")
      this.$emit('setCollection', "0")
    },
    searchLibrary () {
       if (this.searchTerm.length > 0) {
        this.axios.post("/v1/search", { searchterm: this.searchTerm })
        .then((response) => {
          this.$emit('interface', { books: response.data.data, paginationMeta: response.data.links, totalPages: response.data.meta.total })
           this.$emit('fetching', false)
        })
       }
    },
    loadData(page, section) {

      let url = ''
      if (parseInt(section) === 0) {
        url = '/v1/books?page=' + this.currentPage
      }
      else {
        url = '/v1/sections/' + section + '/books?page=' + this.currentPage
      }
      this.axios.get(url)
      .then((response) => {
        this.$emit('interface', { books: response.data.data, paginationMeta: response.data.links, totalPages: response.data.meta.total })
        this.$emit('fetching', false)
      })
    },
    onPageChange(page) {
      localStorage.setItem('bookPage', page)
      this.$emit('fetching', true)
      this.currentPage = page;
      this.loadData(this.currentPage, this.restrictSection)
    }
  }
}
</script>
