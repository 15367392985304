import Vue from "vue";
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from "./App.vue"
import router from "./router"
import Toasted from 'vue-toasted'
import store from "./store"
import moment from 'moment'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(Toasted)
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
// Vue.axios.defaults.baseURL = 'http://localhost:3000'
Vue.router = router
Vue.prototype.moment = moment
Vue.use(require('@websanova/vue-auth'), {
  authRedirect: {path: '/' },
  // tokenExpired: false,
  auth: require('@websanova/vue-auth/drivers/auth/devise.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  rolesVar: 'roles',
  loginData: { url: Vue.axios.defaults.baseURL + '/auth/sign_in', method: 'POST', redirect: '/' },
  logoutData: { url: Vue.axios.defaults.baseURL + '/auth/sign_out', method: 'DELETE', redirect: '/login' },
  refreshData: {
    enabled: true,
    url: Vue.axios.defaults.baseURL + '/auth/validate_token', method: 'GET',
    interval: 5,
  },
  fetchData: {
   url: Vue.axios.defaults.baseURL + '/auth/validate_token', 
   method: 'GET',
   enabled: true
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
