<template>
  <div class="container" v-if="$auth.ready()">
    <table class="table">
      <thead>
        <tr>
          <th>Cat#</th>
          <th>Image</th>
          <th>Title</th>
          <th colspan="2">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="book in books" :key="book.id">
          <td>{{ book.attributes.catno }}</td>
          <td><img v-if="book.attributes.image_url != '/book_missing.png'" :src="book.attributes.image_url" /></td>
          <td>{{ book.attributes.title }} / {{ book.attributes.author }}</td>
          <td>{{ statuses[book.attributes.status_id] }}</td>
          <td><a v-if="book.attributes.status_id === 1" class="is-danger button is-size-7 is-small" href="#/" @click="toggleStatus(book.id, 3)">
            Mark missing</a>
            <a v-if="book.attributes.status_id === 3" class="is-primary button is-size-7 is-small" href="#/" @click="toggleStatus(book.id, 1)">Mark as found</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      statuses: {
        1: 'On shelf',
        2: 'Borrowed',
        3: 'Missing'
      },
      books: []
    }
  },
  mounted () {
    console.log(this.$route )
    let url = `/v1/sections/${this.$route.params.id}/shelfread`
    this.axios.get(url)
    .then((response) => {
      this.books = response.data.data
    })
  },
  methods: {
    toggleStatus(bookId, status) {
      let url = '/v1/books/' + bookId
      this.axios.put(url, { status_id: status })
        .then((response) => {
          this.books.splice(this.books.findIndex(p => p.id === bookId), 1, response.data.data)
        })
    }
  }
}
</script>