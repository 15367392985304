<template>
  <div>
    <Books v-if="done" :restrictSection="this.$route.params.id"  :books="books" :totalPages="totalPages" :fetching="fetching" :searchTerm="searchTerm" :paginationMeta="paginationMeta" @fetching="processFetch" @interface="handleStuff" />
  </div>
<!--   <div v-else>
    <img src="@/assets/ajax-loader.gif"/>
  </div> -->
</template>
<script>
import Books from '@/components/Books.vue'
export default {
  data () {
    return {
      done: false,
      fetching: true,
      paginationMeta: null,
      totalPages: null,
      books: null,
      restrictSection: null
    }
  },
  components: {
    Books
  },
  beforeMount () {
    window.localStorage.setItem('bookPage', 1)
    this.sectionId = this.$route.params.id
    this.axios.get("/v1/sections/" + this.sectionId + "/books")
    .then((response) => {
      this.$emit('interface', { books: response.data.data, paginationMeta: response.data.links, totalPages: response.data.meta.total })
      this.$emit('fetching', false)
      this.done = true
    })
  }
}
</script>
