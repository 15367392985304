<template>
  <div class="container">
    <div class="columns">
      <div class="column is-one-third">
        <h1>Login</h1>
        <form v-on:submit.prevent="login()">
            <div class="field">
              <label class="label">Email:</label>
              <input v-model="data.body.email" type="text" class="input"/>
            </div>
            <div class="field">
              <label class="label">Password:</label>
              <input v-model="data.body.password" class="input" type="password" />
            </div>
            <div class="field">
              <input v-model="data.rememberMe" type="checkbox" /><label class="label">Remember Me</label>
            </div>
            <div class="field">
              <div class="control">
                <button class="button is-success" type="submit">Login</button>
              </div>
            </div>
          <div v-show="error" style="color:red; word-wrap:break-word;">{{ error  }}</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      context: 'login context',

        data: {
          body: {
            email: '',
            password: ''
          },
          rememberMe: false,
          fetchUser: false
        },
        error: null
      };
    },

    mounted() {
      // Can set query parameter here for auth redirect or just do it silently in login redirect.
    },
    methods: {
      login() {
        let redirect = this.$auth.redirect();
        console.log(redirect)
        this.$auth.login({
          data: this.data.body, // Axios
          rememberMe:this.data.rememberMe,
          redirect: {name: redirect ? redirect.from.name : 'Books'},
          fetchUser: false// this.data.fetchUser,
        })
        .then((resp) => {
          console.log(resp.data)
          this.$auth.user(resp.data.data)
          console.log('success ' + this.context);
        }, (res) => {
          console.log('error ' + this.context);
          this.error = res.data;
        })
        .catch((err) => {
          console.log('error: ' )
          console.log(err)
        });
      }
    }
  }
</script>
